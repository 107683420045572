
.top_hold ul{margin: 0;padding: 0;display: flex;list-style: none;}
.head_top{background-color:#ffffff;}
#header{padding-top: 25px;position:absolute;left: 0;top: 0;right: 0; z-index: 8;}
.logo_clss img{z-index: 7;position: relative;}
.logo{display: inline-block;background-color: #002856;padding-bottom: 14px;padding-right: 10px;position: relative;}
.logo::before {position: absolute;content: "";height: calc(100% + 45px);width: 9999px;background-color: #002856;right: 0;bottom: -2px;}
.logo::after{position: absolute;content: ""; clip-path: polygon(0 0, 0% 100%, 100% 100%);background-color: #002856;width:  100px;height: calc(100% + 45px);left: 99%;bottom: -2px;}
.logo .logo_clss{display: inline-block;}
.br{width: 22px;height: 2px;background-color:#6e0e0e;margin-bottom: 4px;}
.nav_holder {justify-content: end; align-items: center;}
.nav_holder ul{margin: 0;padding: 0;list-style: none;display: flex;}
.nav_holder .nav_link{text-decoration: none;color: #454545;font-family: 'Lato_bold';font-size: 17px;transition: all ease ease-in-out 0.3s;}
.nav_holder .nav_link:hover{color: #f69100;}
.nav_holder ul li{margin-right: 40px;}
.nav_holder ul li:last-child{margin-right: 0;}
.login_btn{transition: all ease-in-out 0.3s;background-color: #ffffff; margin-left: 35px; width: 127px;height: 47px;text-decoration: none;border: 2px solid #f69100;border-radius:22px; display: flex;justify-content: center;align-items: center;font-size: 17px;font-family: 'Lato_bold';color: #f69100;}
.login_btn:hover{background-color: #f69100;color: #ffffff;}
@media screen and (max-width:1399px){
    #header{padding-top: 18px;}
    .head_top{ background-color:#ffffff;}
    .text_holder {bottom: 130px;}
    .login_btn {font-size: 16px; width: 122px;height: 44px;margin-left: 22px;}
    .nav_holder ul li {margin-right: 30px;}
}
@media screen and (max-width: 1199px){
#header{padding-top: 16px;}
.logo {padding-bottom: 6px;}
.nav_holder ul li {margin-right: 20px;}
.nav_holder .nav_link {font-size: 16px;}
.login_btn {font-size: 15px;width: 113px;height: 38px;margin-left: 18px;}
.logo::after {width: 71px;}
.text_holder .teleheatth {font-size: 35px;margin-bottom: 23px;}
}
@media screen and (max-width: 991px){
.navbar-light .navbar-toggler-icon{ margin-right: -380px; background-image:url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(241, 119, 0, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")}
.nav_holder .navbar-toggler{padding: 0 ;border: none;}
.navbar {position: static !important;z-index: 8;}
.navbar-collapse{ width:95%; position: absolute;left: 0px; right: 0px;top: 52px; background-color:#002856; margin:  0 auto;}
.nav_holder .navbar-toggler:focus{box-shadow: none;}
.nav_bar{flex-direction: column;}
.nav_holder .nav_link{display: block;color: #ffffff;}
.nav_holder .nav_link
.nav_holder ul li{margin-right:0}
.nav_holder .nav_link:hover {color: #015a98;background-color: #ffffff;}
.nav_link {display: block;padding: 7px 10px;border: none;border-radius:0;}
/* // */
.nav_holder ul li {margin-right: 0;}
.login_btn {font-size: 14px;width: 88px;height: 30px;}
.head_top .logo { padding-bottom: 4;}
.navbar {padding: 0;}
.head_top {padding-top: 2px;padding-bottom: 1px;}

}
@media screen and (max-width: 767px){
    #header {padding-top:0;position: relative;}
    .login_btn {font-size: 14px;width: 74px;height: 27px;}
    .navbar-collapse{top: 36px;}
    .nav_holder .nav_link {display: block;padding: 4px 7px;font-size: 12px;}
    .logo::after {height: calc(100% + 10px);width: 20px;}
    .logo {padding-bottom: 3px;width: 100px;padding-right: 0;}
    .navbar-toggler-icon {width: 25px;height: 25px;}
}