.whatyouget_holder{padding-top: 0px;padding-bottom: 0px;}
.whatyouget_holder .container{max-width: 1250px;}
.whatyou_htag{margin-bottom: 44px; font-size: 36px;font-family: 'Lato_Bold';color: #000000;text-align: center;text-transform: capitalize;}
.p_tagcurehotlincontent{position: relative; font-size: 17px;padding: 9px 25px; font-family: "Lato_Bold";color:#ffffff;width: fit-content;margin: auto;margin-bottom: 42px;text-align: center;}
.span_ptag{position: relative;z-index: 7;}
.p_tagcurehotlincontent::after{ position: absolute;content: ""; clip-path: polygon(50% 0%, 0% 100%, 100% 100%);width: 30px;height: 20px;background-color: #002856;bottom: 90%; left: 32px;}
.p_tagcurehotlincontent::before{position: absolute;content: "";width: 100%; height:100%; background-color: #002856;bottom:0;left: 50%; transform: translateX(-50%) skew(-24deg, 180deg); }
.onlin_htag{font-size: 20px;font-family: 'Lato_Regular';color: #000000;margin-bottom: 30px;}
.logos_ptag{font-size: 17px;font-family: 'Lato_Regular';color: #565757;line-height: 22px;margin: 0;}
.whatyouget_logos_detail_holder{background-color: #f3fbfd;text-align: center;padding: 83px 10px 36px;cursor: pointer;transition: all ease-in-out 0.3s;}
.whatyouget_logos_detail_holder:hover{background-color: #29a6d9;}
.lg1_hold2{display: none;}
.whatyouget_logos_detail_holder:hover .lg1_hold2{display: block;}
.whatyouget_logos_detail_holder:hover .lg1_hold{display: none;}
.whatyouget_logos_detail_holder:hover .logos_ptag{color: #ffffff;}
.whatyouget_logos_detail_holder:hover .onlin_htag{color: #ffffff;}
.lg1_hold2{position: relative;}
.lg1_hold2::before{z-index: -1; background-color: #ffffff; position: absolute;content: "";width: 80px;height: 80px;border: 2px solid #e1e1e1;border-radius: 100%;left: 50%;transform: translateX(-50%);bottom: -13px;}
.lg1_hold{position: relative;}
.lg1_hold::before{z-index: -1; background-color: #ffffff; position: absolute;content: "";width: 80px;height: 80px;border: 2px solid #e1e1e1;border-radius: 100%;left: 50%;transform: translateX(-50%);bottom: -13px;}
.lg_main_hplder{position: absolute;top: -27px;left: 50%;transform: translateX(-50%);}
.whatyouget_logos_detail_holder{margin-bottom: 65px;height: 80%;}
.p_tagparent{margin-bottom: 100px;}
@media screen and (max-width: 1600px){
    .whatyouget_holder .container{max-width: 1200px;}
}
@media screen and (max-width: 1399px){
    .whatyouget_holder .container{max-width: 900px;}
    .lg1_hold {width: 25px;height: 25px;}
    .lg1_hold2 {width: 25px;height: 25px;}
    .lg1_hold::before {width: 60px;height: 60px;bottom: -18px;}
    .lg1_hold2::before {width: 60px;height: 60px;bottom: -18px;}
    .whatyouget_logos_detail_holder {padding: 70px 6px 30px;}
    .lg_main_hplder {top: -14px;}
    .onlin_htag {font-size: 18px;margin-bottom: 25px;}
    .logos_ptag {font-size: 16px;line-height: 17px;}
    .whatyouget_holder {padding-top: 220px;padding-bottom: 105px;}
}
@media screen and (max-width: 1199px){
    .lg1_hold {width: 20px;height: 20px;}
    .lg1_hold2 {width: 20px;height: 20px;}
    .lg1_hold::before {width: 45px;height: 45px;}
    .lg1_hold2::before {width: 45px;height: 45px;}
    .whatyouget_logos_detail_holder {padding: 55px 4px 22px;}
    .onlin_htag {font-size: 16px;margin-bottom: 20px;}
    .logos_ptag {font-size: 14px;line-height: 15px;}
    .whatyouget_holder {padding-top: 148px;padding-bottom: 70px;}
    .whatyouget_logos_detail_holder{padding: 55px 14px 22px;}

}
@media screen and (max-width: 991px){
    .p_tagcurehotlincontent {font-size: 15px; line-height: 18px;}
    .whatyouget_holder .container{max-width: 800px;}
    .whatyou_htag {margin-bottom: 34px;font-size: 32px;}
    .whatyouget_holder {padding-top: 90px;padding-bottom: 30px;}
    .whatyouget_logos_detail_holder {padding: 55px 10px 22px;}
}
@media screen and (max-width: 767px){
    .whatyou_htag {margin-bottom: 23px;font-size: 26px;}
    .whatu_ptag::after {height: 20px;top: -18px;}
    .p_tagcurehotlincontent {font-size: 14px;}
}
@media screen and (max-width: 575px){
    .p_tagparent{padding: 0 22px;}
    .whatyouget_holder {padding-top: 50px;padding-bottom: 15px;}
    .whatyou_htag {margin-bottom: 23px;font-size: 16px;}
    .whatyouget_holder .whatu_ptag span{display: block;}
}

