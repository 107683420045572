.top_footer{padding-top: 116px;margin-bottom: 145px;}
.footright_content_foot{margin-left: 25px;}
.head_linkcontent{display: flex;}
.top_footer .container{max-width: 1250px;}
.bottom_foot .container{max-width: 1250px;}
.footer_heading{font-size: 36px;font-family: 'Lato_Bold';color: #000000;text-align: center;margin-bottom: 27px;}
.foot_quality_ptag{font-size: 17px;font-family: 'Lato_Regular';text-align: center; color: #515151;margin-bottom: 120px;}
.footer_left_inputarea{padding-bottom: 70px; border-radius: 30px;padding-top: 27px;padding-left: 38px;}
.footer_left_inputarea .requestinformation-form .form-control:focus{border-color: #002856;}
.footer_left_inputarea .requestinformation-form .requestinformation-input-area::placeholder{font-size: 14px; color:#000000;}
.footer_left_inputarea .requestinformation-form .requestinformation-input-area{resize: none;box-shadow: none;  outline: none; padding-top: 0;padding-bottom: 0; padding-left: 15px; border-radius: 4px;width: 100%;height: 55px; border: 1px solid #e0e0e0;margin-bottom: 10px;}
.footer_left_inputarea .requestinformation-form .requestinformation-text-area::placeholder{font-size: 14px; color:#000000;}
.footer_left_inputarea .requestinformation-form .requestinformation-text-area{resize: none;box-shadow: none; outline: none; padding-top: 12;padding-bottom: 0; margin-bottom: 25px; padding-left: 15px;  resize: none; outline: none; width: 100%;height: 152px; border: 1px solid #e0e0e0 ;border-radius: 4px;}
.footer_left_inputarea{position: relative;}
.footer_left_inputarea::before{position: absolute;content: "";width: 4px;height: 85%; background-color: #002856;left: 0;}
.background{height: 590px;max-width: 930px;background-color: #f3fbfd;position: absolute;top: -60px;left:0;right: 0;z-index: -1;}
.hole_maker{position: relative;}
.foot_right_data{background-color: #002856;height: 480px;width: 390px;padding: 50px 0 54px  30px;position: relative;}
.foot_right_data::before{position: absolute;content: "";width: 1px;height: 80%;background-color: #ffffff;left: 75px;}
.f_img_hold2{display: none;}
.foot_link{text-decoration: none;display: inline-block;}
.foot_link:hover .f_img_hold2{display: block;}
.f_img_hold ,.f_img_hold2{width: 30px;height: 30px;}
.foot_link:hover .f_img_hold{display:none;}
.foot_link span{font-size: 24px;color: #ffffff;font-family: 'Lato_Bold';text-decoration: none;}
.foot_link:hover span{color: skyblue;}
.foot_ptag{margin-bottom: 25px; font-size: 17px;color: #ffffff;font-family: 'Lato_Regular';text-align: initial;margin:0;}
.contact_num2{margin-bottom: 25px; color: #ffffff; font-size: 17px;font-family: 'Lato_Regular';text-align: initial;text-decoration: none;display: block;}
.contact_num{display: block; color: #ffffff; font-size: 17px;font-family: 'Lato_Regular';text-align: initial;text-decoration: none;}
.contact_email{ color: #ffffff; font-size: 17px;font-family: 'Lato_Regular';text-align: initial;text-decoration: none;}
.full_roght{display: flex;justify-content: end;}
.contact_email_holder{margin-bottom: 37px;}
.fb_icon{color: #ffffff;position: relative;font-size: 20px;}
.fb_icon::before{position: absolute;content: ""; width: 35px;height: 35px;border: 1px solid #ffffff;border-radius: 100%;left: 50%;transform: translateX(-50%);bottom: -4px;}
.links_ui_stuckture:hover .fb_icon{color: darkblue;}
.links_ui_stuckture:hover .fb_icon::before{border-color: darkblue;}
.img_icon_holder2{display: none;}
.links_ui_stuckture:hover .img_icon_holder2{display:block;}
.links_ui_stuckture:hover .img_icon_holder{display:none;}
.foot_right_data ul{margin: 0;padding: 0;display: flex;margin-left:68px ;}
.foot_right_data ul li{margin-right:25px;list-style: none;}
.img_icon_holder{position: relative;width: 20px;height: 20px;}
.img_icon_holder::before{position: absolute;content: ""; width: 35px;height: 35px;border: 1px solid #ffffff;border-radius: 100%;left: 50%;transform: translateX(-50%);bottom: -11px;}
.img_icon_holder2{position: relative;width: 20px;height: 20px;}
.img_icon_holder2::before{position: absolute;content: ""; width: 35px;height: 35px;border: 2px solid darkblue;border-radius: 100%;left: 50%;transform: translateX(-50%);bottom: -11px;}
.foot_btn_holder{text-align: end;}
.submit_btn{width: 140px;height: 53px;font-size: 17px;font-family: "Lato_Bold";color: #ffffff;border-radius: 28px;background-color: #29a6d9;border: 2px solid #29a6d9;}
.submit_btn:hover{background-color: #ffffff;color: #29a6d9;transition: all ease-in-out 0.3s;}
.bottom_foot{text-align: center;background-color: #29a6d9;}
.foot_bottom_hold{padding: 30px 0;}
.bottom_foot p{font-size: 14px;font-family: 'Lato_Regular';color: #ffffff;margin: 0;}
.main_arhamlogo_hold_img{position: absolute;bottom: 0;right: 0;}
.c_style{position: relative;margin: 0 5px;}
.c_style::before{position: absolute;content: ""; width: 15px;height: 15px;border: 1px solid #ffffff;border-radius: 100%;left: 50%;transform: translateX(-50%);bottom: 0;}
@media screen and (max-width: 1600px){
    .top_footer .container{max-width: 1200px;}
    .bottom_foot .container{max-width: 1200px;}
}
@media screen and (max-width: 1399px){
    .top_footer .container{max-width: 900px;}
    .bottom_foot .container{max-width: 900px;}
    .background {height: 492px;max-width: 610px;top: -43px;}
    .footer_left_inputarea .requestinformation-form .requestinformation-input-area {height: 46px;}
    .footer_left_inputarea .requestinformation-form .requestinformation-text-area {height: 128px;}
    .submit_btn {width: 135px;height: 48px;font-size: 16px;}
    .footer_left_inputarea::before {width: 3px;height: 80%;}
    .f_img_hold, .f_img_hold2 {width: 22px;height: 22px;}
    .foot_link span {font-size: 22px;}
    .foot_ptag {font-size: 15px;margin-bottom: 20px;}
    .contact_num {font-size: 15px;}
    .contact_num2 {font-size: 15px;}
    .contact_email {font-size: 15px;margin-bottom: 20px;}
    .contact_email_holder {margin-bottom: 30px;}
    .img_icon_holder::before {width: 28px;height: 28px;}
    .img_icon_holder {width: 14px;height: 14px;}
    .img_icon_holder2::before {width: 28px;height: 28px;}
    .img_icon_holder2 {width: 14px;height: 14px;}
    .foot_right_data::before {left: 55px;}
    .foot_right_data {height: 400px;width: 328px;padding: 40px 0 54px 22px;}
    .footer_heading {font-size: 32px;margin-bottom: 25px;}
    .foot_quality_ptag {font-size: 15px;margin-bottom: 105px;}
    .foot_bottom_hold {padding: 22px 0;}
    .arham_logoholder{width: 100px;height: 100px;}
    .top_footer{padding-top: 108px;margin-bottom: 130px;}
    .fb_icon {font-size: 16px;}
    .fb_icon::before {width: 28px;height: 28px;}
}
@media screen and (max-width: 991px){
    .bottom_foot .container{max-width: 800px;}
    .top_footer .container{max-width: 800px;}
    .topfooter_left_inputarea {padding-left: 23px;}
    .footer_heading {font-size: 28px;margin-bottom: 16px;}
    .foot_quality_ptag {font-size: 14px;margin-bottom: 100px;}
    .f_img_hold, .f_img_hold2 {width: 16px;height: 16px;}
    .foot_link span {font-size: 17px;}
    .foot_ptag {font-size: 14px;margin-bottom: 14px;}
    .contact_num {font-size: 13px;}
    .contact_num2 {font-size: 13px;}
    .contact_email {font-size: 13px;}
    .contact_email_holder {margin-bottom: 22px;}
    .img_icon_holder::before {width: 24px;height: 24px;}
    .img_icon_holder2::before {width: 24px;height: 24px;}
    .img_icon_holder {width: 12px;height: 12px;}
    .img_icon_holder2 {width: 12px;height: 12px;}
    .fb_icon {font-size: 14px;}
    .fb_icon::before {width: 24px;height: 24px;bottom: -2px;}
    .foot_right_data ul li {margin-right: 20px;}
    .foot_right_data ul {margin-left: 47px;}
    .foot_right_data {height: 333px;width: 288px;padding: 32px 0 45px 18px;}
    .foot_right_data::before {left: 41px;}
    .footer_left_inputarea .requestinformation-form .requestinformation-input-area {height: 37px;padding-left: 10px;margin-bottom: 8px;}
    .footer_left_inputarea .requestinformation-form .requestinformation-text-area {height: 107px;padding-left: 8px;}
    .submit_btn {width: 114px;height: 40px;font-size: 14px;}
    .footer_left_inputarea::before {width: 2px;height: 75%;}
    .background {height: 417px;max-width: 525px;top: -40px;}
    .top_footer {padding-top: 100px;margin-bottom: 80px;}
    .footright_content_foot {margin-left: 20px;}
}
@media screen and (max-width: 767px){
    .footer_left_inputarea {padding-bottom: 60px;padding-top: 20px;padding-left: 11px}
    .footer_left_inputarea .requestinformation-form .requestinformation-input-area {height: 32px;padding-left: 4px;margin-bottom: 6px;}
    .footer_left_inputarea .requestinformation-form .requestinformation-text-area {height: 79px;padding-left: 4px;}
    .submit_btn {width: 100px;height: 34px;font-size: 13px;border-radius: 22px;}
    .footer_left_inputarea .requestinformation-form .requestinformation-input-area::placeholder{font-size: 12px;}
    .footer_left_inputarea .requestinformation-form .requestinformation-text-area::placeholder{font-size: 12px;}
    .foot_link span {font-size: 16px;}
    .f_img_hold, .f_img_hold2 {width: 15px;height: 15px;margin-bottom: 9px;}
    .foot_ptag {font-size: 12px;}
    .contact_num {font-size: 12px;}
    .contact_num2 {font-size: 12px;margin-bottom: 14px;}
    .contact_email {font-size: 12px;}
    .img_icon_holder::before {width: 20px;height: 20px;}
    .fb_icon {font-size: 13px;}
    .fb_icon::before {width: 20px;height: 20px;}
    .foot_right_data ul {margin-left: 36px;}
    .foot_right_data ul li {margin-right: 15px;}
    .foot_right_data {height: 276px;width: 243px;padding: 28px 0 40px 14px;}
    .foot_right_data::before {left: 35px;}
    .background {height: 350px;max-width: 365px;top: -40px;}
    .contact_email_holder {margin-bottom: 6px;}
    .top_footer {padding-top: 80px;margin-bottom: 50px;}
    .foot_bottom_hold {padding: 16px 0;}
    .arham_logoholder {width: 90px;height: 90px;}
    .bottom_foot p {padding: 0 48px;}
    .footright_content_foot {margin-left: 15px;}

}
@media screen and (max-width: 575px){
    .top_footer {margin: auto;max-width: 300px;}
    .bottom_foot p {padding: 0 34px;font-size: 12px;}
    .arham_logoholder {width: 70px;height: 70px;}
    .foot_bottom_hold {padding: 7px 0;}
    .c_style::before {width: 12px;height: 12px;}
    .full_roght {justify-content: start;}

}