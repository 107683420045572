.h_tag_left_content_aboutskills{margin-bottom: 40px; font-size: 32px;font-weight: 700;color: #000000;text-transform: uppercase;font-family: 'Lato_Regular';}
.h_tag_right_content_aboutskills{margin-bottom: 40px; font-size: 32px;font-weight: 700;color: #000000;text-transform: uppercase;font-family: 'Lato_Regular';}
.abouskillsandexperience_holder{padding-top: 110px;}
.p_tag_left_content_aboutskills{font-size: 27px;font-weight: 400px;font-family: 'Lato_Regular';color: #000000;}
/* .progressbar_holder{display: cont;} */
.progressbar_holder .progress .bg-success{border-radius: 5px; padding: 15px 0; border: 1px solid rgba(241, 119, 0, 1); background-color: rgba(241, 119, 0, 1) !important; border-bottom-right-radius: 0;border-top-right-radius: 0;}
.progress_number{position: absolute;right:auto; width: 30px;height: 20px;bottom: 100%;}
.progress{overflow: visible;background-color: #ffffff;}
.progress.right .progress-bar {
    float: right;
  }
.progress-bar {overflow: visible;position: relative;}
.progressTag {
   border: 2px solid rgb(63, 63, 63);;
   width: 30px;
   height: 30px;
   display: flex;
   justify-content: center;
   align-items: center;
    position: absolute;color: #ffffff;background-color: rgb(63, 63, 63);bottom: 37px;
    left: 95%;border-radius: 5px;position: relative;}
.progressTag::after {
    position: absolute;
    content: "";
    clip-path: polygon(50% 50%, 100% 50%, 50% 100%, 0% 50%);
    width: 19px;
    height: 19px;
    background-color: rgb(63, 63, 63);;
    bottom: -7px;
}
.progresbar_props_holder{margin-bottom: 35px;}
.progress_htag{font-size: 18px;color: #000000;font-family: 'Lato_Italic';font-weight: 400;margin-bottom: 20px;}
@media screen and (max-width: 1399px){
    .h_tag_left_content_aboutskills {font-size: 28px;}
    .h_tag_right_content_aboutskills {font-size: 28px;}
    .p_tag_left_content_aboutskills{font-size: 24px;line-height: 30px;}
}
@media screen and (max-width:991px){
    .p_tag_left_content_aboutskills {font-size: 18px;line-height: 22px;}
    .progressbar_holder .progress .bg-success {padding: 12px 0;}
}
@media screen and (max-width:767px){
    .p_tag_left_content_aboutskills {font-size: 14px;line-height: 18px;}
    .progress_htag {font-size: 16px;margin-bottom: 18px;}
    .progressbar_holder .progress .bg-success {padding: 10px 0;}
    .h_tag_left_content_aboutskills {font-size: 20px;}
    .h_tag_right_content_aboutskills {font-size: 20px;}
}