.trendingnftslider_holder .slick-slide{padding: 0px 10px;}
.trendingnftslider_holder .slick-dots li button {width: 134px;height: 10px;padding: 0;}
.trendingnftslider-mg{padding: 10px 10px 20px; background-color: #22192b;border-radius: 23px;}
.trendingnftslider_holder{padding-top: 60px;padding-bottom: 50px;}
 .left_side h3{line-height: 22px; font-family: 'PoppinsRegular'; font-size: 12px;margin: 0;padding: 0;color: #745596;}
 .right_side h3{line-height: 22px; font-family: 'PoppinsRegular'; font-size: 12px;margin: 0;padding: 0;color: #745596;}
.btn_trending_slider{text-decoration: none;color: #948d9f;position: relative;font-size: 12px;font-family: "PoppinsRegular";}
.btn_trending_slider::before{ position: absolute;content: "";width: 100%;height: 2px;bottom: -2px;right: 0;}
.btn_trending_slider:hover::before{background-color: #948d9f;transition: all ease-in-out 0.3s;}
.btn_trending_slider:hover{color: #948d9f;}
.trendin_heading_detail h3{line-height: 22px; font-size: 14px;font-family: 'PoppinsRegular';color: #ffffff;margin: 0;}
.trendingnftslider_holder .slick-dots li.slick-active button:before {color:#f86f64;height: 5px;padding: 5px 67px;background: #fd5492;background: -moz-linear-gradient(top,  #fd5492 0%, #f86f64 100%);background: -webkit-linear-gradient(top,  #fd5492 0%,#f86f64 100%);background: linear-gradient(to bottom,  #fd5492 0%,#f86f64 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fd5492', endColorstr='#f86f64',GradientType=0 );}
.trendingnftslider_holder .slick-dots li {display: inline-block;background-color: #1a1226;height: 6px; width: 135px;margin: 0;padding: 0;}
.trendingnftslider_holder .slick-dots li button:before{ color: #1a1226;;line-height: 0;}
.trendingnftslider_holder .slick-dots{bottom: -58px;position: relative;}
.trendingnftslider_holder .slick-dots::before{position: absolute;content: "";width: 675px;height: 3px;background-color: #373142;bottom:-3px;}
.trendingnftslider_holder .slick-next:before{font-size: 46px;color: #0d0913;}
.trendingnftslider_holder .slick-prev:before{font-size: 46px;color: #0d0913;}
.trendingnftslider_holder .slick-next{z-index: 1;right: 5px;}
.trendingnftslider_holder .slick-prev{left: -22px;z-index: 1;}
.hold_by{align-items: center;}
.trending_pointscalculate{ position: absolute;top: -9px;right: 0;font-size: 12px;font-family: 'PoppinsRegular'; padding: 3px;background: #301d43;border-radius: 6px;color: #c2bdc7;}
.trendin_heading_detail{padding: 2px 29px;margin-bottom: 10px;}
.trending_yellow_img{margin: 0 5px;}
.main_hold_left_right{ padding: 0 29px;align-items: center; justify-content: space-between;position: relative;}
.main_hold_left_right::before{position: absolute;content: "";height: 59px;width: 100%;bottom: -9px;background-color: #281d33;border-radius: 18px;left:50%;transform: translateX(-50%);}
.left_side{z-index: 7;}
.right_side{z-index: 7;}
.eth_cls{color: #ffffff;font-family: 'PoppinsRegular';font-size: 14px;}
.trending_imglogo{width: 18px;z-index: 7;height: 40px;}
.trending_imglogo img{width: 100%;height: 100%;}
@media screen and (max-width:1399px){
    .main_hold_left_right::before {height: 55px;border-radius: 13px;}
    .trendingnftslider_holder .slick-dots li button {width: 133px;height: 6px;}
    .trendingnftslider_holder::before{height: 300px;}
    .trendingnftslider_holder .slick-dots li.slick-active button:before {height: 3px;padding: 3px 67px;}
    .trendingnftslider_holder .slick-dots::before {bottom:0;}
    .trendingnftslider_holder .slick-prev:before {font-size: 40px;}
    .trendingnftslider_holder .slick-next:before {font-size: 40px;}
    .trendingnftslider_holder .slick-next{right: 0;}
    .trendin_heading_detail {padding: 2px 21px;}
    .trending_imglogo {width: 16px;height: 33px;}
    .trending_yellow_img {width: 12px;height: 12px;}
} 
@media screen and (max-width:1199px){
    .trendin_heading_detail {padding: 2px 8px;margin-bottom: 5px;}
    .main_hold_left_right {padding: 0 8px;}
}
@media screen and (max-width:991px){
    .trendingnftslider_holder .slick-dots li button {width:77px;height: 6px;}
    .trendingnftslider_holder{padding-top: 60px;padding-bottom: 23px;}
    .trendingnftslider_holder .slick-dots::before {width: 390px;bottom:0;}
    .trendingnftslider_holder .slick-dots li {width: 78px;}
    .trendingnftslider_holder .slick-dots li.slick-active button:before {padding: 3px 38px;}
    .trendingnftslider_holder .slick-dots {bottom: -30px;}
    .trendingnftslider_holder .slick-next:before {font-size: 29px;}
    .trendingnftslider_holder .slick-prev:before {font-size: 29px;}
    .trendingnftslider_holder .slick-prev{left: -9px;}
    .main_hold_left_right::before {bottom: -5px; border-radius: 10px;}
    .trendingnftslider-mg {padding: 7px 7px 14px;border-radius: 13px;}
    .trendin_heading_detail {padding: 1px 3px;margin-bottom: 5px;}
    .main_hold_left_right {padding: 0 3px;}
}
@media screen and (max-width:767px){
    .main_hold_left_right::before {bottom: -3px;border-radius: 6px;height: 39px;}
    .trending_imglogo {width: 12px;height: 23px;}
    .left_side h3{font-size: 11px;line-height: 17px;}
    .eth_cls {font-size: 12px;}
    .right_side h3 {line-height: 17px;font-size: 11px;}
    .btn_trending_slider {font-size: 11px;}
    .trending_pointscalculate {top: -11px;font-size: 11px;}
    .trendin_heading_detail h3 {line-height: 18px;font-size: 12px;}
    .trendingnftslider-mg {padding: 5px 5px 10px;background-color: #22192b;border-radius: 12px;}
}

@media screen and (max-width:480px){
    .trendin_heading_detail {padding: 1px 26px;}
    .main_hold_left_right {padding: 0 26px;}
    .trendingnftslider-mg {max-width: 335px;margin: auto;}
    .trendingnftslider_holder .slick-dots::before {width: 175px;}
    .trendingnftslider_holder .slick-dots li button {width: 34px;}
    .trendingnft_slider_img img{margin:auto;}
    .trendingnftslider_holder .slick-dots li.slick-active button:before {padding: 3px 17px;}
    .trendingnftslider_holder .slick-dots li {width: 35px;}
    .trendingnftslider_holder .slick-next {right: 58px;}
    .trendingnftslider_holder .slick-prev {left: 50px;}
}
@media screen and (max-width:479px){
    .trendingnftslider_holder .slick-next {right: 230px;top: 276px;}
    .trendingnftslider_holder .slick-prev {left: 230px;top: 276px;}
    .trendingnft_slider_img{width: 220px;height: 270px;margin: auto;}
    .trendingnft_slider_img img{width:100%;height: 100%;}
    /* .notable {font-size: 15px;} */
    .trendingnftslider_holder .slick-next:before {font-size: 22px;}
    .trendingnftslider_holder .slick-prev:before {font-size: 22px;}
    /* .notable::before {height: 2px;bottom: -10px;width: 99px;} */
    .trendingnftslider-mg {max-width: 245px;}
    /* .trendingnftslider-mg::before {border-radius: 10px;} */
}

@media screen and (max-width:360px){
    .trendingnftslider_holder .slick-next {right: 178px;}
    .trendingnftslider_holder .slick-prev {left: 168px;}

}
@media screen and (max-width:290px){
    /* .trendingnftslider-mg {max-width: 245px;border-radius: 18px;padding: 5px 5px 94px;max-width: none;} */

}
