.aboushowtheworld_holder{ padding-top: 100px;}
.h_tag_showtheworld{position: relative; font-size: 52px;color: #000000;font-family: 'Lato_Regular';text-align: center;text-transform: uppercase;font-weight: 700;margin-bottom: 55px;}
.h_tag_showtheworld::before{position: absolute;content: "";width: 100px;height: 4px;background-color: #f17700;left: 50%; transform: translateX(-50%);bottom: -33px;}
.p_tag_showtheworld{text-align: center;color:#636363;font-size: 16px;}
.aboushowtheworld_hold{padding: 0 300px;}

/* .aboutonclick_holder{padding: 50px 0;}
.onclick_link{margin-bottom: 5px; border: 1px solid #ee2852; padding: 5px 30px;background-color: #ee2852;text-decoration: none;color: #ffffff;text-transform: uppercase;font-size: 14px;}
.onclick_link2{border: 1px solid #ffffff; padding: 5px 30px;background-color:orange;text-decoration: none;color: #000000;text-transform: uppercase;font-size: 14px;}
.disc_icon{font-size: 20px;margin-right: 10px;}
.cardshoping_icon{color: blue;font-size: 16px;margin-right: 10px;}
.aboutonclick_hold{display: inline-grid; position: fixed;top: 100px; right: 0;z-index: 7;}
.onclick_page_show{width:225px;height: 496px;background-color:whitesmoke;padding: 20px;position: relative;}
.disc2_icon{color: #ee2852;font-size: 30px;margin-right: 10px;}
.interactive{font-size: 16px;color: #000000;margin: 0;}
.heading_onclick{display: flex; align-items: center;margin-bottom: 12px;}
.relatedthems{font-size: 14px; color: #000000;text-transform: uppercase;margin-bottom: 5px;}
.ametrine{font-size: 16px;text-transform: capitalize;color: #000000;}
.creative_cls{display: flex;justify-content: space-between;align-items: center;}
.creative_cls h2{font-size: 12px;margin: 0;color: gray;text-transform: uppercase;}
.creative_cls span{font-size: 12px;color: #000000;}
.onclick_bottom_link{color: #ffffff; position: absolute;bottom: 0;left: 50%;transform: translateX(-50%); text-transform: uppercase;font-size: 12px; text-decoration: none;display: flex;justify-content: center;align-items: center;width: 100%;height: 35px;background-color: #ee2852;}
.arrowup_icon{margin-left: 8px; color: #ffffff;position: relative;}
.arrowup_icon::before{position: absolute;content: ""; width: 6px;height: 2px;background-color: #ffffff;bottom: 3px;left: -2px;}
 */

/*  */
@media screen and (max-width:1399px){
    .aboushowtheworld_hold{padding: 0 170px;}
    .h_tag_showtheworld {font-size: 38px;}
}
@media screen and (max-width:1199px){
    .aboushowtheworld_hold{padding: 0 140px;}
    .h_tag_showtheworld {font-size: 32px;}
    .p_tag_showtheworld {font-size: 15px;}
}
@media screen and (max-width:991px){
    .aboushowtheworld_hold{padding: 0 90px;}
    .aboushowtheworld_holder {padding-top: 80px;}
}
@media screen and (max-width:767px){
    .aboushowtheworld_hold{padding: 0 8px;}
    .h_tag_showtheworld {font-size: 28px;}
    .p_tag_showtheworld {font-size: 14px;}
}
@media screen and (max-width: 575px){
    .h_tag_showtheworld {font-size: 14px;margin-bottom: 40px;}
    .aboushowtheworld_hold{padding:0}
    .h_tag_showtheworld::before {width: 66px;height: 3px;bottom: -21px;}
    .aboushowtheworld_holder {padding-top: 45px;}
}