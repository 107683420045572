
.about_slide_holder{background-image: url('../../assets/images/aboutbanner.jpg');background-repeat: no-repeat; height: 600px;    background-size: cover;background-position: center center;}
.about_slide_holder{display: flex;justify-content: center;align-items: center;}
.about_slide_holder .container{max-width: 1250px;}
.h_tag_aboutslider{text-align: center;color: #ffffff;font-size: 62px;font-family: 'Lato_Regular';font-weight:bold;text-transform: uppercase;}
.p_tag_aboutslider{text-align: center;color: #ffffff;font-size: 38px;line-height: 50px; font-family: 'Lato_Regular';font-weight: 30;}
.about_slide_holder .slick-slider .slick-prev,
.about_slide_holder .slick-slider .slick-next{font-size: 42px;color: #ffffff;}
.about_slide_holder .slick-slider .slick-prev::before,
.about_slide_holder .slick-slider .slick-next::before{display: none;}
.about_slide_holder .slick-dots li button{display: none;}
.bottom_h_tag_aboutslider{font-size: 22px;color: #ffffff;text-transform: uppercase;font-family: 'Lato_Regular';font-weight: 700;text-align: center;}
@media screen and (max-width: 1600px){
    .about_slide_holder .container{max-width: 1200px;}
}
@media screen and (max-width: 1399px){
    .about_slide_holder .container{max-width: 900px;}
    .h_tag_aboutslider {font-size: 48px;}
    .p_tag_aboutslider {font-size: 30px;line-height: 35px;}
    .about_slide_holder{height: 500px;}
    .about_slide_holder .slick-slider .slick-prev,
    .about_slide_holder .slick-slider .slick-next{font-size: 36px;}
}
@media screen and (max-width: 1199px){
    .about_slide_holder .slick-slider .slick-prev,
    .about_slide_holder .slick-slider .slick-next{font-size: 32px;}
    .h_tag_aboutslider {font-size: 42px;}
    .p_tag_aboutslider {font-size: 26px;}

}
@media screen and (max-width: 991px){
    .about_slide_holder .container{max-width: 800px;}
    .about_slider_content_holder{padding: 0 30px;}
    .about_slide_holder {height: 370px;}
    .about_slide_holder .slick-slider .slick-prev{left: 0;font-size: 28px;}
    .about_slide_holder .slick-slider .slick-next{right: 0;font-size: 28px;}
    .h_tag_aboutslider {font-size: 36px;}
    .p_tag_aboutslider {font-size: 20px;line-height: 25px;}
}
@media screen and (max-width: 767px){
    .h_tag_aboutslider {font-size: 28px;}
    .p_tag_aboutslider {font-size: 18px;line-height: 22px;}
    .about_slide_holder {height: 280px;}
}
@media screen and (max-width: 575px){
    .about_slider_content_holder{padding: 0 10px;}
    .h_tag_aboutslider {font-size: 16px;}
    .p_tag_aboutslider {font-size: 14px;line-height: 17px;}
    .about_slide_holder {height: 218px;}
    .about_slide_holder .slick-slider .slick-prev,
    .about_slide_holder .slick-slider .slick-next{font-size: 20px;color: #ffffff;}
    .bottom_h_tag_aboutslider{font-size: 16px;}

}