.banner_holder{position: relative;z-index: 7;}
.banner_shape{position: absolute;top: 0;width: 135%;}
.banner_holder .slick-dots {bottom: 44px;z-index: 11;position: absolute;left: 80%;transform: translateX(-50%);}
.banner_shape img{width: 100%;}
.slider-img-head1 img{width: 100%;}
.banner_holder .slick-dots li button {width: 20px;height: 4px;background: #d2d2d2;padding: 0;border-radius: 30px;}
.banner_holder li.slick-active button:before {height: 4px;padding: 0 10px;background-color: #2caee2;line-height: 0;border-radius: 30px;}
.banner_holder .slick-dots li button:before {width: 20px;height: 4px;border-radius: 30px;}
.banner_holder .slick-dots li {width: 20px;height: 4px; margin: 1px;border-radius: 30px;}
.banner_holder .slick-dots li button:before {font-family: 'slick';font-size:0;line-height: 0;border-radius: 30px;}
.text_holder .teleheatth{font-size: 67px;color: #fafafa;font-family: 'Lato_Black';text-transform: uppercase;margin: 0;margin-bottom: 44px;}
.text_holder .everyone{display: block;}
.p_tag{font-size: 30px;font-family: 'Lato_Light';color: #ffffff;margin-bottom: 68px;}
.btn_talk_to_d {transition: all ease-in-out 0.3s; border-radius: 20px;padding: 2px 12px;font-size: 14px;color: gray;border: 1px solid gray;background-color: #f9f7f6;}
.btn_talk_to_d:hover{background-color: gray; color: #ffffff;}
.angleright_icon{position: relative;margin-left: 50px;font-size: 20px;}
.angleright_icon::before{left: 50%;transform: translateX(-50%);bottom: 0; position: absolute;content: "";width: 25px;height: 25px;border: 1px solid #ffffff;border-radius: 100%;}
.oncallremote{text-decoration: none;text-transform: capitalize;font-size: 14px;font-family: 'Lato_Regular';color: #2caee2;margin-bottom: 16px;display: block;}
.oncallremote:hover{color: #208abc;}
.text_holder{position: absolute;top: 35%;left: 10%; right: 10%;}
.men_img_holder{width: 50px;height: 50px;}
.men_img_holder2{width: 50px;height: 50px;}
.main_men_imgholder ul{list-style: none;margin: 0;padding: 0;display: flex;justify-content: center;}
.main_men_imgholder ul li{margin-right: 100px;}
.main_men_imgholder ul li:last-child{margin-right: 0;}
.main_men_imgholder .men_img_holder2{display: none;}
.main_men_imgholder .men_links:hover .men_img_holder2{display: block;}
.main_men_imgholder .men_links:hover .men_img_holder{display: none;}
.value_lating{font-size: 17px;font-family: 'Lato_Bold';color: #2caee2;margin-left: 20px;}
.main_men_imgholder{margin-bottom: 25px;}
.useclss_for_margin{margin-bottom: 43px;}

.value_lating:hover{color: #208abc;}
.main_men_imgholder .men_links{text-decoration: none;display: flex;align-items: center;}
@media screen and (max-width: 1600px){
    .text_holder .teleheatth {font-size: 53px;margin-bottom: 38px;}
    .p_tag{font-size: 25px;margin-bottom: 60px;}
    .angleright_icon {margin-left: 44px;font-size: 17px;}
    .angleright_icon::before {width: 22px;height: 22px;}
    .text_holder {bottom: 150px;}
}
@media screen and (max-width:1399px){
    .men_img_holder{width: 30px;height: 30px;}
    .men_img_holder2{width: 30px;height: 30px;}
    .text_holder {bottom: 130px;}
    .text_holder .teleheatth {font-size: 48px;margin-bottom: 35px;}
    .p_tag {font-size: 22px;margin-bottom: 44px;}

}
@media screen and (max-width:1299px){
    .text_holder .teleheatth {font-size: 42px;margin-bottom: 27px;}
    .p_tag {font-size: 20px;margin-bottom: 41px;}
    .angleright_icon::before {width: 20px;height: 20px;bottom: -1px;}
    .angleright_icon {margin-left: 41px;font-size: 16px;}
}
@media screen and (max-width:1199px){
    .p_tag {font-size: 18px;margin-bottom: 37px;}
    .text_holder { bottom: 90px;}
    .main_men_imgholder ul li {margin-right: 44px;}
}
@media screen and (max-width:991px){
    .value_lating {font-size: 14px;margin-left: 15px;}
    .text_holder .teleheatth {font-size: 25px;margin-bottom: 21px;}
    .p_tag {font-size: 16px;margin-bottom: 30px;}
    .angleright_icon {margin-left: 17px;font-size: 14px;}
    .angleright_icon::before {width: 17px;height: 17px;}
    .text_holder {bottom: 82px;}
}
@media screen and (max-width:767px){
    .text_holder .teleheatth {font-size: 22px;margin-bottom: 18px;}
    .p_tag {font-size: 14px;margin-bottom: 27px;}
    .angleright_icon {margin-left: 11px;font-size: 12px;}
    .text_holder { bottom: 36px;}
    .main_men_imgholder ul li {margin-right: 20px;}
    .men_img_holder {width: 25px;height: 25px;}
    .men_img_holder2 {width: 25px;height: 25px;}
}
@media screen and (max-width:575px){
    .main_men_imgholder {margin-bottom: 16px;}
    .useclss_for_margin{margin-bottom: 29px;}
    .text_holder .teleheatth {font-size: 20px;margin-bottom: 18px;}
    .p_tag {font-size: 14px;margin-bottom: 23px;}
    .angleright_icon::before {width: 15px;height: 15px;bottom: 0;}
    .banner_holder .slick-dots li button {width: 15px;height: 4px;padding: 0;border-radius: 30px;}
    .banner_holder li.slick-active button:before {height: 4px;padding: 0 5px;line-height: 0;border-radius: 30px;}
    .banner_holder .slick-dots li button:before {width: 15px;height: 4px;border-radius: 30px;}
    .banner_holder .slick-dots li {width: 15px;height: 4px; margin: 1px;border-radius: 30px;}
    .banner_holder .slick-dots li button:before {font-family: 'slick';font-size:0;line-height: 0;border-radius: 30px;}
    .text_holder {text-align: center;position: initial;}
}
@media screen and (max-width:460px){
    .text_holder{position: relative;bottom: 130px ; left: 0px; padding: 0 0px;}
    .banner_holder .slick-dots {position: absolute;left: 50%;bottom: 0;}
    .p_tag { display: flex; justify-content: center;font-size: 14px;flex-wrap: wrap;margin-bottom: 15px;}

}
