.ourexperts_holder{background-color: #f3fbfd;margin-top: 110px;padding: 30px;} 
.ourexperts_holder .container{max-width: 1250px;}
.ourexpert_htag{color: #29a6d9;font-size: 36px;font-family: 'Lato_Bold';text-transform: capitalize;margin-bottom: 35px;}
.Lets_clear{font-size: 14px;color: gray;text-transform: initial;}
.ourexperts_holder .ourexpers_rightside .accodion_holder .accordion-button:focus{box-shadow: none;}
.accordion-button {font-size: 16px;padding: 20px 7px;position: relative;font-family: 'Lato_Bold';color: #000000;}
.accordion-button::after{background-image: url('../../assets/images/test.png');}
.accordion-button[aria-expanded="true"]::after {background-image: url('../../assets/images/mainus.png');}
/* .accordion-item:first-of-type .accordion-button{color: #000000;} */
.accordion-button:not(.collapsed){color: #000000;background: #ffffff;border-bottom: 1px solid rgb(243, 237, 237);}
.accodion_holder{margin-bottom: 26px;}
.ourexperts_holder .ourexpers_rightside .b-holder .b-slide-down{
   position: relative;
   background-color: #ffffff;
   border: none;
   color: #29a6d9;
   width: 274px;height: 54px;
   font-size: 17px;
   font-family: 'Lato_Bold';
   border: 2px solid #29a6d9;
   border-radius: 25px;
   }
    .ourexperts_holder .ourexpers_rightside .b-holder .b-slide-down::before{
    content: '';
     position: absolute;
     border-radius: 25px;
     top: 0;
     left: -2px;
     right: -2px;
     bottom: 0;
     background-color: #29a6d9;
     transition: all 0.5s;
     opacity: 1;
     /* transform: scale(1.1); */
     }
    .ourexperts_holder .ourexpers_rightside .b-holder .b-slide-down:hover .b-name{ color:#29a6d9;}
    .ourexperts_holder .ourexpers_rightside .b-holder .b-slide-down:hover::before{
     right: 100%;
     opacity: 0;
   }
    .ourexperts_holder .ourexpers_rightside .b-holder .b-slide-down .b-name{ 
     position: relative;     
     color: #ffffff;
     font-weight: bold;
     top: 0; left: 0;
     transition: 0.3s;
   }

/* btn end */
@media screen and (max-width: 1600px){
    .ourexperts_holder .container{max-width: 1200px;}
}
@media screen and (max-width: 1399px){
    .ourexperts_holder .container{max-width: 900px;}
    .ourexpert_htag {font-size: 32px;}
    .ourexperts_holder .ourexpers_rightside .b-holder .b-slide-down{width: 260px;height: 52px;}
}
@media screen and (max-width: 991px){
    .ourexperts_holder .container{max-width: 800px;}
    .accordion-button {font-size: 14px;}
    .ourexperts_holder .ourexpers_rightside .b-holder .b-slide-down{width: 255px;height: 48px;}
}
@media screen and (max-width:767px)
{
    .ourexperts_holder .ourexpers_rightside .b-holder .b-slide-down{width: 240px;height: 42px;}
    .doctor_hold{text-align: center;}
    .ourexperts_holder{padding: 0 17px;}
}
@media screen and (max-width:576px)
{
    .ourexperts_holder .ourexpers_rightside .b-holder .b-slide-down{width: 210px;height: 40px;font-size: 14px;}
    .ourexperts_holder {padding: 0px 5px;}
    .ourexpert_htag {font-size: 16px;}
    .accordion-button {font-size: 14px;padding: 12px 5px;}
    .accordion-body{font-size: 14px;padding: 5px;}
    .b-holder{text-align: center;}
    .Lets_clear {font-size: 12px;}

}