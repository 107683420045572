.aboutbanner_holder{background-image: url('../../assets/images/aboutbanner.jpg');background-repeat: no-repeat; height: 600px;    background-size: cover;background-position: center center;}
.aboutbanner_holder{position: relative;z-index: 7;display: flex;justify-content: center;align-items: center;}
.h_tag_gettoknowus{text-transform: uppercase;color: #ffffff;font-family: "Lato_Regular";font-size: 86px;font-weight: 700;text-align: center;}
.p_tag_gettoknowus{font-size: 25px;font-weight: 400;font-family: "Lato_Regular";color: #ffffff;text-align: center;}

/* //// */

@media screen and (max-width:1399px){
    .aboutbanner_holder{height: 500px;}
    .h_tag_gettoknowus {font-size: 65px;}
    .p_tag_gettoknowus {font-size: 18px;}
}
@media screen and (max-width:1199px){
    .aboutbanner_holder{height: 440px;}
    .h_tag_gettoknowus {font-size: 60px;}
    .p_tag_gettoknowus {font-size: 16px;}
}
@media screen and (max-width:991px){
    .aboutbanner_holder{height: 370px;}
    .h_tag_gettoknowus {font-size: 55px;}
    .p_tag_gettoknowus {font-size: 15px;}
}
@media screen and (max-width:767px){
    .aboutbanner_holder{height: 230px;}
    .h_tag_gettoknowus {font-size: 42px;}
    .p_tag_gettoknowus {font-size: 14px;}
}
@media screen and (max-width: 575px){
    .aboutbanner_holder{height: 160px;}
    .h_tag_gettoknowus {font-size: 26px;}
    .p_tag_gettoknowus {font-size: 14px;}

}