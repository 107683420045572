
.foot_requestinformation_form .foot_requestinformation_input_area{font-size: 12px; padding-right: 140px; padding-top: 0;padding-bottom: 0; padding-left: 15px; background-color: #dddddd;border-radius: 8px;width: 572px;height: 70px;  resize: none;outline: none;}
.foot_requestinformation_form .form-control:focus {outline: 0;box-shadow:none;background-color: #231833;border: 1px solid #231833;color:#ffffff;}
.foot_requestinformation_form .foot_requestinformation_input_area::placeholder{color:#fcfcfc;font-family: 'PoppinsRegular';font-size: 12px;}
.btn_holder .btn_foot{padding: 0; border-radius: 5px; background-color: #5c2da1;width: 115px;height: 59px;border: 2px solid #5c2da1;color: #ffffff;font-family: 'PoppinsRegular';font-size: 18px;}
.btn_holder .btn_foot:hover{border-radius: 5px; background-color:#ffffff;color: #5c2da1;border: 2px solid #5c2da1;transition: all ease-in-out 0.3s;}
.btn_holder{position: absolute;top: 5px;right: 60px;}
.left_foot_holder .foot_input_hold{margin-bottom: 85px;}
.footer_top{padding-top: 90px;margin-bottom: 58px;}
.active_tabtext_holder{margin-bottom: 43px;}
.left_foot_holder{padding-left: 50px;}
.right_foot_holder{padding-left: 214px;}
.get_lastest_htag{margin-bottom: 28px; font-size: 30px;font-family: 'PoppinsSemiBold';color: #000000;}
.join_elumnt_htag{font-size: 30px;font-family: 'PoppinsSemiBold';color: #020202;margin-bottom: 39px;}
.ul_li_structured_holder ul{list-style: none;margin: 0;padding: 0;display: flex;}
.ul_li_structured_holder ul li .foot_icons{font-size: 36px;color: #a3a4a5;}
.ul_li_structured_holder ul li{margin-right:58px;}
.elumnt_text{font-size: 30px;margin-bottom: 28px; color: #000000;font-family: 'PoppinsSemiBold';}
.marketheading{font-size: 30px;margin-bottom: 28px; color: #000000;font-family: 'PoppinsSemiBold';}
.holder_elumt_tag p{color: #837298;font-size: 22px;margin: 0;font-family: 'PoppinsRegular';}
.holder_elumt_tag{padding-left: 50px;}
.icon_hovering{text-decoration: none;}
.ul_li_structured_holder ul li .foot_icons:hover{color:#8b77a8;}
.holder_market_tag{padding-left: 160px;}
.holder_market_tag ul{margin: 0;padding: 0;list-style: none;}
.holder_market_tag ul li .foot_links{text-decoration: none;font-size: 22px;color:#837298;font-family: 'PoppinsSemiBold';line-height: 24px;}
.holder_market_tag ul li .foot_links:hover{color: #ccb8e4;}
.holder_Ecossystem_tag ul{margin: 0;padding: 0;list-style: none;}
.holder_market_tag ul li{margin-bottom: 29px;}
.holder_Ecossystem_tag ul li{margin-bottom: 29px;}
.holder_Ecossystem_tag ul li .foot_links{line-height: 24px; text-decoration: none;font-size: 22px;color:#837298;font-family: 'PoppinsSemiBold';}
.holder_Ecossystem_tag ul li .foot_links:hover{color: #ccb8e4;}
.ul_li_structured_holder ul li:last-child{margin-right:0;}
.holder_bottom_foot{position: relative;padding-top: 36px;padding-bottom: 79px;}
.holder_bottom_foot::before{position: absolute;content: "";width: 100%;height: 2px;background-color: #342a42;top: 0;left: 50%;transform: translateX(-50%);}
.footer_bottom h4{font-family: 'PoppinsRegular'; margin: 0;color:#342a42;font-size: 19px;}
.footer_bottom .holder_bottom_foot .c_style{position: relative; width: 17px;height: 17px; border: 1px solid #342a42;border-radius: 100%;}
.footer_bottom .holder_bottom_foot .c_form{font-size: 19px;color: #342a42;position: absolute;bottom: -3px;left: 3px;}
.holder_bottom_foot{align-items: center;}
.foot_icons{font-size: 18px;color:#ffffff;}
@media screen and (max-width:1439px){
    .foot_requestinformation_form .foot_requestinformation_input_area{width: 532px;height: 70px;}
    .btn_holder .btn_foot{font-size: 17px;}
}
@media screen and (max-width:1399px){
    .holder_market_tag ul li {margin-bottom: 22px;}
    .holder_Ecossystem_tag ul li {margin-bottom: 22px;}
    .foot_requestinformation_form .foot_requestinformation_input_area{width: 482px;height: 60px;padding-right: 120px;}
    .btn_holder .btn_foot {width: 106px;height: 50px;font-size: 16px;}
    .left_foot_holder .foot_input_hold {margin-bottom: 70px;}
    .get_lastest_htag {margin-bottom: 22px;font-size: 28px;}
    .btn_sign_up {font-size: 16px; margin-right: 12px;width: 110px;height: 50px;}
    .join_elumnt_htag {font-size: 28px;margin-bottom: 28px;}
    .right_foot_holder {padding-left: 165px;}
    .ul_li_structured_holder ul li {margin-right: 55px;}
    .ul_li_structured_holder ul li .foot_icons {font-size:30px;}
    .left_foot_holder {padding-left: 10px;}
    .holder_elumt_tag {padding-left: 10px;}
    .holder_market_tag {padding-left: 102px;}
    .elumnt_text {font-size: 24px;margin-bottom: 22px;}
    .marketheading {font-size: 24px;margin-bottom: 22px;}
    .holder_elumt_tag p {font-size: 18px;}
    .holder_market_tag ul li .foot_links {font-size: 18px;}
    .holder_Ecossystem_tag ul li .foot_links {font-size: 18px;}
    .footer_bottom h4 {font-size: 16px;}
    .footer_bottom .holder_bottom_foot .c_form {font-size: 16px;bottom: -3px;left: 2px;}
    .footer_bottom .holder_bottom_foot .c_style{ width: 16px;height: 16px;}
    .holder_bottom_foot {padding-top: 30px;padding-bottom: 61px;}
}
@media screen and (max-width:1199px){
    .foot_requestinformation_form .foot_requestinformation_input_area {width: 392px;height: 50px;}
    .btn_holder .btn_foot {width: 90px;height: 40px;font-size: 14px;}
    .footer_bottom h4 {font-size: 14px;}
    .footer_bottom .holder_bottom_foot .c_form {font-size: 14px;bottom: -2px;left: 2px;}
    .footer_bottom .holder_bottom_foot .c_style{ width: 15px;height: 15px;}
    .holder_bottom_foot {padding-top: 25px;padding-bottom: 55px;}
    .right_foot_holder {padding-left: 140px;}
    .footer_top {padding-top: 57px;}
    .get_lastest_htag {margin-bottom: 14px;font-size: 22px;}
    .join_elumnt_htag {font-size: 22px;margin-bottom: 22px;}
    .btn_sign_up {font-size: 14px;margin-right: 9px;width: 100px;height: 40px;}
    .ul_li_structured_holder ul li .foot_icons {font-size: 25px;}
    .ul_li_structured_holder ul li {margin-right: 43px;}
    .elumnt_text {font-size: 22px;margin-bottom: 12px;}
    .marketheading {font-size: 22px;margin-bottom: 12px;}
    .holder_elumt_tag p {font-size: 16px;}
    .holder_market_tag ul li .foot_links {font-size: 16px;}
    .holder_Ecossystem_tag ul li .foot_links {font-size: 16px;}
    .holder_market_tag ul li {margin-bottom: 16px;}
    .holder_Ecossystem_tag ul li {margin-bottom: 16px;}
    .footer_top {margin-bottom: 37px;}
}
@media screen and (max-width:991px){
    .foot_requestinformation_form .foot_requestinformation_input_area {width: 272px;height: 40px;padding-right: 90px;}
    .btn_holder .btn_foot {width: 75px;height: 30px;font-size: 12px;}
    .footer_top {margin-bottom: 24px;}
    .footer_bottom h4 {font-size: 12px;}
    .footer_bottom .holder_bottom_foot .c_form {font-size: 12px;bottom: -1px;left: 2px;}
    .footer_bottom .holder_bottom_foot .c_style{ width: 13px;height: 13px;}
    .holder_bottom_foot {padding-top: 20px;padding-bottom: 50px;}
    .footer_top {padding-top: 42px;}
    .get_lastest_htag {margin-bottom: 14px;font-size: 17px;}
    .btn_sign_up {font-size: 12px;margin-right: 6px;width: 75px;height: 28px;}
    .join_elumnt_htag {font-size: 17px;margin-bottom: 14px;}
    .ul_li_structured_holder ul li {margin-right: 25px;}
    .ul_li_structured_holder ul li .foot_icons {font-size: 18px;}
    .elumnt_text {font-size: 17px;margin-bottom: 10px;}
    .marketheading {font-size: 17px;margin-bottom: 10px;}
    .holder_elumt_tag p {font-size: 13px;}
    .holder_market_tag ul li .foot_links {font-size: 13px;}
    .holder_Ecossystem_tag ul li .foot_links {font-size: 13px;}
    .holder_market_tag {padding-left: 34px;}
    .right_foot_holder {padding-left: 117px;}
    .holder_market_tag ul li {margin-bottom: 10px;}
    .holder_Ecossystem_tag ul li {margin-bottom: 10px;}
}
@media screen and (max-width:767px){
    .foot_requestinformation_form .foot_requestinformation_input_area {margin: auto;padding-right: 5px;padding-left: 5px;}
    .left_foot_holder{text-align: center;}
    .btn_holder {top:50px;right: 0;left: 0;}
    .left_foot_holder .foot_input_hold {margin-bottom: 52px;}
    .holder_bottom_foot {padding-top: 18px;padding-bottom: 40px;}
    .right_foot_holder {padding-left: 0;text-align: center;}
    .ul_li_structured_holder ul {justify-content: center;}
    .holder_elumt_tag {text-align: center;padding-left: 0;margin-bottom: 20px;}
    .ul_li_structured_holder{margin-bottom: 10px;}
    .holder_elumt_tag p {padding: 0 61px;}
    .holder_bottom_foot {padding-top: 17px;padding-bottom: 35px;}
    .holder_market_tag {padding-left: 132px;}
}
@media screen and (max-width:575px){
    .holder_market_tag {padding-left: 0;text-align: center;margin-bottom: 20px;}
    .holder_Ecossystem_tag{text-align: center;}
    .holder_market_tag ul li .foot_links {font-size: 12px;}
    .holder_Ecossystem_tag ul li .foot_links {font-size: 12px;}
    .holder_Ecossystem_tag ul li {margin-bottom: 6px;}
    .holder_market_tag ul li {margin-bottom: 6px;}
    .marketheading {font-size: 16px;}
    .elumnt_text {font-size: 16px;}
    .join_elumnt_htag {font-size: 16px;}
    .get_lastest_htag {font-size: 16px;}

}
@media screen and (max-width:360px){
    .holder_bottom_foot {padding-top: 15px;padding-bottom: 30px;}
    .get_lastest_htag {font-size: 14px;}
    .btn_sign_up {font-size: 11px;margin-right: 5px;width: 65px;height: 25px;}
    .join_elumnt_htag {font-size: 14px;}
    .ul_li_structured_holder ul li {margin-right: 12px;}
    .elumnt_text {font-size: 13px;}
    .holder_elumt_tag p {padding: 0px 10px;font-size: 11px;}
    .marketheading {font-size: 14px;margin-bottom: 6px;}
    .holder_market_tag ul li .foot_links {font-size: 11px;}
    .holder_Ecossystem_tag ul li .foot_links {font-size: 11px;}
    .foot_requestinformation_form .foot_requestinformation_input_area {width: 224px;height: 32px;border-radius: 5px;}
    .btn_holder .btn_foot {width: 68px;height: 27px;font-size: 11px;border-radius: 5px;}

}

@media screen and (max-width: 767px) {
    
}