/* .counting_num_holder{background-image: url('../../assets/images/aboutbanner.jpg');background-repeat: no-repeat; height: 400px;    background-size: cover;background-position: center center;display: flex;justify-content: center; align-items: center;} */
.counting_num_holder{background-color: blue;height: auto;}
.countTag{font-size: 82px;color: #ffffff;font-family: 'Lato_Bold';font-weight: bold; }
.data_num_hold{text-align: center;}
.view_port_holder{text-align: center;}
.products_name{font-size: 24px;color: #ffffff;font-family: 'Lato_Italic';font-weight: 400;position: relative;}
.products_name::before{position: absolute;content: "";width: 100px;height: 6px;top: 0;background-color:#e27d23;left: 50%;transform: translateX(-50%);top: -23px;}
@media screen and (max-width:1399px){
    .countTag {font-size: 75px;}
    .products_name::before {width: 80px;top: -28px;}
    .products_name {font-size: 22px;}
}
@media screen and (max-width:991px){
    .countTag {font-size: 55px;}
    .products_name::before {width: 63px;top: -30px;}
    .products_name {font-size: 18px;}
    /* .counting_num_holder {height: 300px;} */
}
@media screen and (max-width:767px){
    .countTag {font-size: 38px;}
    .products_name::before {width: 45px;top: -30px;height: 4px;}
    .products_name {font-size: 16px;}
}
@media screen and (max-width:575px){
    .countTag {font-size: 22px;}
    .products_name {font-size: 14px;}
    /* .counting_num_holder {height: auto;} */
}