.whatwetreat_slider_hole .container{max-width: 1250px;}
.whatwetreat_slider_hole{padding-top: 60px;padding-bottom: 70px;}
.name{font-size: 16px;color:#454545;text-align: center;font-family: 'Lato_Bold';text-transform: capitalize;}
.whatwetreat_slider_hole .slick-slide{padding: 0px 10px;}
.main_ul_holder{background-color: #88bcec;}
.slider_img_head1{width: 50px;height: 50px;margin: auto;}
.whatwetreat_slider_hole .slick-slider .slick-prev,
.whatwetreat_slider_hole .slick-slider .slick-next{font-size: 20px;color: #2da8da;border: 1px solid #2da8da;width: 30px;height: 30px;border-radius: 100%;}
.whatwetreat_slider_hole .slick-slider .slick-prev::before,
.whatwetreat_slider_hole .slick-slider .slick-next::before{display: none;}
.whatwetread_heading{margin-bottom: 44px; font-size: 36px;font-family: 'Lato_Bold';color: #000000;text-align: center;}
.p_tagcurehotlincontent{position: relative; font-size: 17px;padding: 9px 25px; font-family: "Lato_Bold";color:#ffffff;width: fit-content;margin: auto;margin-bottom: 42px;text-align: center;}
.span_ptag{position: relative;z-index: 7;}
.p_tagcurehotlincontent::after{ position: absolute;content: ""; clip-path: polygon(50% 0%, 0% 100%, 100% 100%);width: 30px;height: 20px;background-color: #002856;bottom: 90%; left: 32px;}
.p_tagcurehotlincontent::before{position: absolute;content: "";width: 100%; height:100%; background-color: #002856;bottom:0;left: 50%; transform: translateX(-50%) skew(-24deg, 180deg); }
.main_ul_holder ul{list-style: none;margin: 0;padding: 0;}
.main_ul_holder ul li{border-bottom: 1px dotted #a7dae8;padding: 19px  0;}
.main_ul_holder ul li:last-child{border-bottom: none;padding-bottom: none;}
.main_ul_holder ul li h2{margin: 0;color: #ffffff;font-size: 15px;font-family: 'Lato_Light';margin-left: 16px;}
.whatwetreat_slider_hole .whatwetreat_viewall{z-index: 7; margin-right: 30px; text-decoration: none;font-size: 17px;font-family: 'Lato_Bold';color: #88bcec;}
.viewall_right_angle{font-size: 20px;color:#88bcec ;}
.whatwetreat_slider_hole .whatwetreat_viewall:hover{color: #002856;}
.whatwetreat_slider_hole .whatwetreat_viewall:hover .viewall_right_angle{color: #002856;}
.what_we_link_holder{display: flex;justify-content: end;}
.whatwetreat_slider_hole .slick-dots li button {display: none;}
@media screen and (max-width: 1600px){
    .whatwetreat_slider_hole .container{max-width: 1200px;}
}
@media screen and (max-width: 1399px){
    .whatwetreat_slider_hole .container{max-width: 900px;}
    .main_ul_holder ul li {padding: 16px 0;}
}
@media screen and (max-width: 991px){
    .whatwetreat_slider_hole .container{max-width: 800px;}
    .p_tagcurehotlincontent {font-size: 15px;}
}
@media screen and (max-width: 767px){
    .p_tagcurehotlincontent {font-size: 14px; line-height: 18px;}
    .whatwetread_heading {font-size: 28px;}
    .whatwetreat_slider_hole {padding: 0 17px;}
    .whatwetreat_slider_hole .slick-slider .slick-prev,
    .whatwetreat_slider_hole .slick-slider .slick-next{font-size: 16px;color: #2da8da;border: 1px solid #2da8da;width: 20px;height: 20px;border-radius: 100%;}
}
@media screen and (max-width: 576px){
    .whatwetread_heading {font-size: 22px;}
    .whatwetreat_slider_hole .slick-slider .slick-next {right: 0;}
    .whatwetreat_slider_hole .slick-slider .slick-prev {left:0;z-index: 7;}
    .p_tagparent{padding: 0 22px;}
}