.testing{position: absolute;content: ""; width: 200px;height: 35px;background-color: #f8a700;left: 0;top: 80px;}
.testt{position: absolute;content: ""; width: 200px;height: 35px;background-color: #f8a700;left: 0;top: 80px;}
.daimont_cls{ position: absolute;content: "";width: 80px;height: 80px;top: 0;left: 80px;  background-image: url('../../assets/images/daimond.png');background-repeat: no-repeat; background-size: cover;background-position: center center;}
.aboutanimation_holder{height: 300px;background-color: red;position: relative;display: flex;justify-content: center;align-items: center;}
.pand_img_hold{width: 200px;height: 100px;}
.aboutanimation_hold{display: flex;justify-content: center;align-items: center;}
.pand_img_holdleft{width: 70px;height: 70px;}
.pand_img_holdright{width: 70px;height: 70px;}
.daimont_cls{transform: scale(0,0); transition: 0.3s;}
.daimont_cls.active{transform: scale(1,1); }
.testt{ transition: 0.3s;width: 0;}
.testt.active{width: 200px;}
/* ////////////////////////////// */