.curehotlinecontent_holder .container{max-width: 1250px;}
.curecontent_htag{margin-bottom: 25px; font-size: 36px;font-family: 'Lato_Bold';color: #000000;text-align: center;text-transform: capitalize;margin-bottom: 35px;}
.curehotlinecontent_holder{padding-top: 50px;background-color: #f4fbfd;}
.p_tagcurehotlincontent{position: relative; font-size: 17px;padding: 9px 25px; font-family: "Lato_Bold";color:#ffffff;width: fit-content;margin: auto;margin-bottom: 42px;text-align: center;}
.span_ptag{position: relative;z-index: 7;}
.p_tagcurehotlincontent::after{ position: absolute;content: ""; clip-path: polygon(50% 0%, 0% 100%, 100% 100%);width: 30px;height: 20px;background-color: #002856;bottom: 90%; left: 32px;}
.p_tagcurehotlincontent::before{position: absolute;content: "";width: 100%; height:100%; background-color: #002856;bottom:0;left: 50%; transform: translateX(-50%) skew(-24deg, 180deg); }
.ptag_2{color:#6e6f6f;font-size: 17px;font-family: 'Lato_Regular';padding-bottom: 60px;text-align: center;}
.video_styling{width: 100%;height: 100%;margin: 0;padding: 0;object-fit: cover;border-radius: 10px;}
.video_holder{height: 455px;width: 100%;}
.three_content_imghold{margin-bottom: 56px;}
.content_htag{font-size: 36px;font-family: "Lato_Bold";color: #000000;margin-bottom: 97px;}
.spn_howtodo{display: block;}
.content_ptag{font-size: 17px;font-family: 'Lato_Regular';color: #111111;margin-bottom: 47px;}
.one{ position: relative;font-size: 20px; width: 55px;height: 55px;border-radius: 100%;background-color: #208abc;color: #ffffff;display: flex;justify-content: center;align-items: center;}
.one::before{position: absolute;content: ""; width: 72px;height: 72px;border: 2px solid #208abc;border-radius: 100%;}
.counting_links{ text-decoration: none;font-size: 20px;font-family: 'Lato_Bold';display: inline-block;}
.counting_links:hover .one{background-color:#065174 ;}
.counting_links:hover .one::before{border-color: #065174;}
.counting_links:hover{color: #065174;}
.ptag_counting{font-size: 17px;font-family: 'Lato_Regular';color: #555555;margin: 0;}
.one_style ul{list-style: none;margin: 0;padding: 0;}
.one_style ul li{display: flex;}
.onestyle_ul_right{margin-left: 20px;margin-bottom: 25px;}
@media screen and (max-width: 1600px){
    .curehotlinecontent_holder .container{max-width: 1200px;}
}
@media screen and (max-width: 1399px){
    .curehotlinecontent_holder .container{max-width: 900px;}
    .content_htag {font-size: 32px;margin-bottom: 60px;}
    .three_content_imghold {padding-right: 60px; margin-bottom: 36px;}
    .content_ptag {font-size: 16px;margin-bottom: 27px;}
    .one::before {width: 55px;height: 55px;}
    .one {font-size: 19px;width: 45px;height: 45px;}
    /* .ptag_counting {font-size: 16px;margin-left: 67px;}     */
    .video_holder {height: 439px;}
    .curecontent_htag {margin-bottom: 22px;font-size: 32px;}
    .p_tagcurehotlincontent {font-size: 16px;}
    .ptag_2 {font-size: 16px;margin-bottom: 114px;}
    .curehotlinecontent_holder {padding-top: 100px;}
}
@media screen and (max-width: 991px){
    .curehotlinecontent_holder .container{max-width: 800px;}
    .curecontent_htag {margin-bottom: 22px;}
    .p_tagcurehotlincontent {font-size: 15px;}
    .curehotlinecontent_holder {padding-top: 90px;}
    .ptag_2 {font-size: 15px;margin-bottom: 100px;}
    .content_htag {font-size: 26px;}
    .counting_links {font-size: 17px;}
    .one::before {width: 45px;height: 45px;}
    .one {font-size: 16px;width: 35px;height: 35px;}
    .ptag_counting {font-size: 15px;line-height: 20px;}
    .content_ptag {font-size: 15px;margin-bottom: 25px;}
    .three_content_imghold {padding-right: 135px;margin-bottom: 56px;}
    .video_holder {height: 395px;}
    .value_lating {font-size: 12px;margin-left: 5px;}

}
@media screen and (max-width: 767px){
    .curecontent_htag {font-size: 22px;}
    .curehotlinecontent_holder {padding-top: 60px;}
    .p_tagcurehotlincontent {font-size: 14px; line-height: 18px;}
    .ptag_2 {font-size: 14px;line-height: 20px;margin-bottom: 80px;}
    .content_htag {font-size: 22px;margin-bottom: 45px;}
    .content_ptag {font-size: 14px;line-height: 18px;margin-bottom: 18px;}
    .one::before {width: 24px;height: 24px;}
    .one {font-size: 14px;width: 18px;height: 18px;}
    .ptag_counting {font-size: 14px;line-height: 18px;}
    .video_holder {height: 350px;}
    .three_content_imghold {padding-right: 100px;margin-bottom: 36px;}
    .onestyle_ul_right {margin-left: 8px;margin-bottom: 10px;}
    .counting_links {font-size: 15px;}
}
@media screen and (max-width: 575px){
    .video_holder {height: auto;}
    .three_content_imghold {margin-bottom: 30px;width: 210px;padding: 0;}
    .curecontent_htag {font-size: 17px;}
    .ptag_2 {font-size: 13px;line-height: 16px;margin-bottom: 38px;}
    .content_htag {font-size: 19px;line-height: 20px;margin-bottom: 15px;}
    .content_ptag {font-size: 13px;line-height: 16px;margin-bottom: 16px;}
    .main_men_imgholder ul {display: block;}
    .main_men_imgholder {display: flex;justify-content: center;}
    .main_men_imgholder ul li {margin-right: 0;margin-bottom: 5px;}
    .one {font-size: 14px;width: 24px;height: 24px;}
    .ptag_counting {font-size: 13px;line-height: 18px;}
    .p_tagparent{padding: 0 22px;}
    .one_style ul li {display: block;}
    .one::before {width: 30px;height: 30px;}
    .counting_links {font-size: 15px;}
    .onestyle_ul_right {margin-left: 0;}
    .one_style{padding-left: 8px;}
}