
@font-face {
  font-family: 'Lato_Regular';
  src: url('./assets/fonts/Lato-Regular.woff2') format('woff2'),
      url('./assets/fonts/Lato-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato_HairlineItalic';
  src: url('./assets/fonts/Lato-HairlineItalic.woff2') format('woff2'),
      url('./assets/fonts/Lato-HairlineItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato_Light';
  src: url('./assets/fonts/Lato-Light.woff2') format('woff2'),
      url('./assets/fonts/Lato-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato_Hairline';
  src: url('./assets/fonts/Lato-Hairline.woff2') format('woff2'),
      url('./assets/fonts/Lato-Hairline.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Lato_Italic';
  src: url('./assets/fonts/Lato-Italic.woff2') format('woff2'),
      url('./assets/fonts/Lato-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato_BoldItalic';
  src: url('./assets/fonts/Lato-BoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Lato-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato_Bold';
  src: url('./assets/fonts/Lato-Bold.woff2') format('woff2'),
      url('./assets/fonts/Lato-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato_BlackItalic';
  src: url('./assets/fonts/Lato-BlackItalic.woff2') format('woff2'),
      url('./assets/fonts/Lato-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato_Black';
  src: url('./assets/fonts/Lato-Black.woff2') format('woff2'),
      url('./assets/fonts/Lato-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato_Hairline';
  src: url('./assets/fonts/Lato-Hairline.woff2') format('woff2'),
      url('./assets/fonts/Lato-Hairline.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}




/* ///// */










@media (min-width: 1750px){
  .container {max-width:1710px;}
}


#root{overflow: hidden;}